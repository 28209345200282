import { CacheLocation } from '@auth0/auth0-spa-js';

export const environment = {
  production: true,
  auth: {
    domain: 'colibri-dev.eu.auth0.com',
    response_type: 'token',
    clientId: 'RGxnTgJGOVWvuVwmdTIDrMSQ1K6L39Nu',
    cacheLocation: 'localstorage' as CacheLocation,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'colibri-iot',
    },
  },
  serverUrl: 'https://colibri-dev.uat.link/api',
};
